import { LinearProgress } from '@material-ui/core';
import { AllowedUsageBudgets, OrgUsageSummary } from './service';

export const UsageProgressBar: React.FC<{ objectKey: AllowedUsageBudgets; summary: OrgUsageSummary; postFix: string; title: string }> = ({
  objectKey,
  summary,
  postFix,
  title,
}) => {
  const entry = summary?.summaries?.[objectKey];

  if (!entry) return null;

  return (
    <div key={`budget-${objectKey}`} className={`${entry?.usage && entry.usage > 100 ? 'bg-red-400 font-bold' : ''} p-2 rounded`}>
      <p>{title}</p>
      <LinearProgress variant="determinate" value={entry?.usage ?? 0} />
      <p className="font-mono">
        {entry?.usage?.toFixed(2)} % | {entry?.currentValue?.toFixed(2)} {` ${postFix}`} / {entry?.budgetValue?.toFixed(2)}
        {` ${postFix}`}
      </p>
    </div>
  );
};
