import { Button, CircularProgress, FormControl, FormControlLabel, FormLabel, LinearProgress, Radio, RadioGroup, TextField } from '@material-ui/core';
import { Alert } from '@mui/lab';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { OrgUsageSummary, PlatformUsageBudget, createBudgetService, deleteBudgetService, fetchOrgUsageSummary } from './service';
import { UsageProgressBar } from './UsageProgressBar';
import { addYears, endOfDay, format, startOfDay } from 'date-fns';

const sortBudgets = (a: OrgUsageSummary['allBudgets'][0], b: OrgUsageSummary['allBudgets'][0]) => {
  const getPriority = (budget: OrgUsageSummary['allBudgets'][0]): number => {
    if (budget.isExpired) {
      return 5;
    }
    if (budget.isActive) {
      if (budget.datetime_availiable_from || budget.datetime_availiable_to) {
        return 1;
      }
      if (budget._id) {
        return 2;
      }
      return 4;
    }
    if (!budget.isActive && (budget.datetime_availiable_from || budget.datetime_availiable_to)) {
      return 3;
    }
    return 6; // For any budgets that don't fit the above categories
  };

  const priorityA = getPriority(a);
  const priorityB = getPriority(b);

  if (priorityA !== priorityB) {
    return priorityA - priorityB;
  }

  // If priorities are equal, sort by available from date descending
  const dateA = a.datetime_availiable_from ? new Date(a.datetime_availiable_from).getTime() : 0;
  const dateB = b.datetime_availiable_from ? new Date(b.datetime_availiable_from).getTime() : 0;

  return dateB - dateA;
};

export const SalesTab: React.FC = () => {
  const { id: organizationid } = useParams<{ id: string }>();

  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitting },
    reset,
    watch,
  } = useForm<Partial<PlatformUsageBudget>>();

  const [isLoading, setIsLoading] = useState(false);
  const [summary, setSummary] = useState<OrgUsageSummary>();
  const [usageWithActivityStatus, setUsageWithActivityStatus] = useState<OrgUsageSummary>();
  const today = new Date();

  async function fetchData() {
    setIsLoading(true);
    try {
      const summary = await fetchOrgUsageSummary(organizationid);
      setSummary(summary);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  }

  async function createBudget(values: Partial<PlatformUsageBudget>) {
    try {
      await createBudgetService({
        ...values,
        organization: organizationid,
        datetime_availiable_from: values.datetime_availiable_from ? startOfDay(new Date(values.datetime_availiable_from)).toISOString() : undefined,
        datetime_availiable_to: values.datetime_availiable_to ? endOfDay(new Date(values.datetime_availiable_to)).toISOString() : undefined,
      });
      fetchData();
    } catch (err) {
      console.error(err);
    }
    reset();
  }

  async function deleteBudget(budgetId: string) {
    try {
      await deleteBudgetService(budgetId);
      fetchData();
    } catch (err) {
      console.error(err);
    }
  }

  function getFormattedDefaultDate() {
    return format(addYears(new Date(watch('datetime_availiable_from') ?? today), 1), 'yyyy-MM-dd');
  }

  useEffect(() => {
    reset();
    fetchData();
  }, []);

  useEffect(() => {
    if (summary) {
      const _formattedUsage = {
        ...summary,
        allBudgets: summary.allBudgets.map((budget) => ({
          ...budget,
          isActive: Boolean(
            (!budget.datetime_availiable_from && !budget.datetime_availiable_to) ||
              (budget.datetime_availiable_from && !budget.datetime_availiable_to && today >= new Date(budget.datetime_availiable_from)) ||
              (budget.datetime_availiable_from &&
                budget.datetime_availiable_to &&
                today >= new Date(budget.datetime_availiable_from) &&
                today <= new Date(budget.datetime_availiable_to))
          ),
          isExpired: Boolean(budget.datetime_availiable_to && today > new Date(budget.datetime_availiable_to)),
        })),
      };
      setUsageWithActivityStatus(_formattedUsage);
    }
  }, [summary]);

  return (
    <div className="flex flex-col space-y-6">
      {isLoading && <CircularProgress />}
      {summary && (
        <div className="flex flex-col space-y-4">
          {summary.hasUsageAlert && <Alert severity="error">Organization is overusing budgets!</Alert>}

          <b>Usage</b>
          <UsageProgressBar objectKey="maxParallelUsers" summary={summary} postFix="users" title="Max parallel users" />
          <UsageProgressBar objectKey="video" summary={summary} postFix="min" title="Video" />
          <UsageProgressBar objectKey="stream" summary={summary} postFix="min" title="Stream" />
          <UsageProgressBar objectKey="admins" summary={summary} postFix="" title="Admins" />
          <UsageProgressBar objectKey="subusers" summary={summary} postFix="" title="Subusers" />
          <UsageProgressBar objectKey="emails" summary={summary} postFix="mails" title="E-Mails" />
        </div>
      )}
      <b>Budgets</b>
      {!isLoading && (!summary || summary.allBudgets.length === 0) && <>No data</>}
      {usageWithActivityStatus?.allBudgets?.sort(sortBudgets).map((budget, idx) => (
        <div
          key={`budget-${idx}-${budget.value}-${budget.type}`}
          className={`flex flex-row justify-between p-4 rounded border ${budget.isActive ? 'bg-blue-50 border-blue-300' : 'bg-gray-50'} ${
            budget.isExpired ? 'opacity-70 border-dashed' : ''
          }`}>
          <div>
            <p className="text-sm text-gray-500">Type: {budget.type}</p>
            <p>{budget.description}</p>
            <p className="font-mono">
              {budget.value}
              {['stream', 'video'].includes(budget.type) && ' min'}
            </p>
            <p>
              {budget.datetime_availiable_from && budget.datetime_availiable_to
                ? `${format(new Date(budget.datetime_availiable_from), 'dd.MM.yyyy')} - ${format(new Date(budget.datetime_availiable_to), 'dd.MM.yyyy')}`
                : ''}
            </p>
          </div>
          <div>
            {budget._id && (
              <Button variant="outlined" onClick={() => deleteBudget(budget._id!)}>
                Delete
              </Button>
            )}
          </div>
        </div>
      ))}
      <hr />
      <form onSubmit={handleSubmit(createBudget)} className="flex flex-col">
        <div className="font-bold">Add new Budget</div>
        <Controller
          control={control}
          name="type"
          defaultValue="video"
          render={({ field: { onChange, value } }) => (
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">Type</FormLabel>
              <RadioGroup onChange={(e, value) => onChange(value)} value={value} aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                <FormControlLabel value="video" control={<Radio />} label="Videominutes" />
                <FormControlLabel value="stream" control={<Radio />} label="Streamminutes" />
                <FormControlLabel value="admins" control={<Radio />} label="Admin" />
                <FormControlLabel value="subusers" control={<Radio />} label="Subusers" />
                <FormControlLabel value="emails" control={<Radio />} label="Mails" />
                <FormControlLabel value="maxParallelUsers" control={<Radio />} label="Max parallel users" />
              </RadioGroup>
            </FormControl>
          )}
        />
        <TextField disabled={isSubmitting} {...register('description')} label="Description" />
        <TextField required disabled={isSubmitting} type="number" label="Value" {...register('value', { valueAsNumber: true, required: true })} />
        <TextField
          disabled={isSubmitting}
          type="date"
          label="From"
          defaultValue={format(today, 'yyyy-MM-dd')}
          {...register('datetime_availiable_from')}
          inputProps={{ max: watch('datetime_availiable_to') }}
        />
        <TextField
          disabled={isSubmitting}
          type="date"
          label="To"
          defaultValue={getFormattedDefaultDate()}
          {...register('datetime_availiable_to')}
          inputProps={{ min: watch('datetime_availiable_from') }}
        />
        <Button type="submit" color="primary" disabled={isSubmitting}>
          Save
        </Button>
      </form>
    </div>
  );
};
